//时间戳转换成yyyy-mm-dd HH:mm:ss
function formatDate(getdate) {
    var date = new Date(parseInt(getdate) * 1000);
    var YY = date.getFullYear() + '-';
    var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
    var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
    return YY + MM + DD + " " + hh + mm + ss;
}

// 设备-打印机状态-返回-对应中文值
function printStatusData(printstatusoption, printstatus) {
    let printstatusname = '';
    let printstatusnum = printstatusoption
    for (let j = 0; j < printstatusnum.length; j++) {
        const element = printstatusnum[j];
        if (printstatus) {
            if (element.value == printstatus) {
                if (printstatus === '') {
                    printstatusname = '数据异常';
                    return printstatusname
                } else {
                    printstatusname = element.label;
                    return printstatusname
                }
            }
        } else {
            printstatusname = '数据异常';
            return printstatusname
        }
    }
    return printstatusname
}


export default {
    formatDate,
    printStatusData,

}