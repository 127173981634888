import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn' // 配置国际化-兼容时间组件
import App from './App.vue'
import router from './router/index'
import axios from 'axios'
import './assets/iconfont/iconfont.css' //自制图标
import * as echarts from 'echarts' //echarts

import utils from './assets/js/utils' //自定义公共js文件

import { ElMessage } from 'element-plus'

const app = createApp(App, {
    //这里可自定义全局变量，且在vue组件中用props接收
})

app.use(ElementPlus, {
    locale: zhCn
}) //挂载elementplus,且设置中文
app.use(router)
app.config.globalProperties.$utils = utils //配置自定义js全局函数，并在vue组件中使用【this.$utils.函数名】方式引用函数
app.config.globalProperties.$echarts = echarts //配置的echarts图表
app.config.globalProperties.$axios = axios //配置axios的原型&更改(使用globalProperties进行全局配置)

// 添加-响应拦截器
axios.interceptors.response.use(
    function (response) {
        // 响应数据点
        if (response.data.code == 101) {
            ElMessage({
                message: response.data.message != '' ? response.data.message : 'ErroCode:101',
                type: 'error',
                duration: 1000,
                onClose: () => {
                    // 当数据点code返回101时，跳回登录页
                    router.push('/').catch((error) => error)
                    localStorage.clear()
                }
            })
        } else if (response.data.code == 100) {
            ElMessage({
                message: response.data.message != '' ? response.data.message : '操作失败！',
                type: 'error'
            })
            return response
        } else {
            if (response.data.code == 0) {
                if (response.data.message == '正在导出，请进入导出列表，下载导出文件') {
                    ElMessage({
                        message: response.data.message,
                        type: 'success'
                    })
                }
            }
            return response
        }
    },
    function (error) {
        // 响应错误点
        return Promise.reject(error)
    }
)

// 判断-指定环境&配置
if (process.env.NODE_ENV == 'development') {
    app.config.globalProperties.$bucketfile = 'JSGENERAL/dev/' // 全局存储-bucket空间中的子文件夹
    app.config.globalProperties.$bucketurl = 'dev-test-yinboshi' // 全局存储-bucket空间
    app.config.globalProperties.$suburl = 'http://dev.jsshareprint.com:9091/' //登录跳转-租户平台-地址 - 开发
    // app.config.globalProperties.$suburl = "http://localhost:8081/"; //登录跳转-租户平台-地址 - 开发
    app.config.globalProperties.$baseurl = 'https://dev.jsshareprint.com' // 全局存储后端地址 - 开发
    axios.defaults.baseURL = 'https://dev.jsshareprint.com' // 反向代理URL - 开发
} else if (process.env.NODE_ENV == 'test') {
    app.config.globalProperties.$bucketfile = 'JSGENERAL/test/' // 全局存储-bucket空间中的子文件夹
    app.config.globalProperties.$bucketurl = 'dev-test-yinboshi' // 全局存储-bucket空间
    app.config.globalProperties.$suburl = 'http://demo.jsshareprint.com:9091/' //登录跳转-租户平台-地址
    app.config.globalProperties.$baseurl = 'https://demo.jsshareprint.com' // 全局存储后端地址 - 测试
    axios.defaults.baseURL = 'https://demo.jsshareprint.com' // 反向代理URL - 测试
} else if (process.env.NODE_ENV == 'production') {
    app.config.globalProperties.$bucketfile = 'JSGENERAL/prod/' // 全局存储-bucket空间中的子文件夹
    app.config.globalProperties.$bucketurl = 'js-print-data' // 全局存储-bucket空间
    app.config.globalProperties.$suburl = 'https://rent.jsyinba.com/' //登录跳转-租户平台-地址 - 线上
    app.config.globalProperties.$baseurl = 'https://home.jsyinba.com' // 全局存储后端地址 - 线上
    axios.defaults.baseURL = 'https://home.jsyinba.com' // 反向代理URL - 线上
}

app.mount('#app')
