import {
    createRouter,
    createWebHistory
} from "vue-router";
const routes = [
    //主页面路由,需加入id字段,与后端返的json数据对应 --------------------------------------------
    {
        id: 529,
        path: '/companylist',
        name: 'companylist',
        component: () =>
            import ('@/views/collaborate/company/companylist.vue')
    },
    {
        id: 25,
        path: '/sharedvccontrol',
        name: 'sharedvccontrol',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrol.vue')
    },
    {
        id: 26,
        path: '/sharedvcmap',
        name: 'sharedvcmap',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcmap.vue')
    },
    {
        id: 441,
        path: '/sharedvcmap2',
        name: 'sharedvcmap2',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcmap2.vue')
    },
    {
        id: 27,
        path: '/sharedvcexfactory',
        name: 'sharedvcexfactory',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcexfactory.vue')
    },
    {
        // 临时任务（20230215）: 新出一个设备出厂记录表-给资金方展示使用！
        id: 46,
        path: '/sharedvcexfactoryz',
        name: 'sharedvcexfactoryZijinfang',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcexfactoryZijinfang.vue')
    },
	{
	    // 临时任务（20230215）: 欧力士
	    id: 731,
	    path: '/sharedvcexfactoryzols',
	    name: 'sharedvcexfactoryZijinfangols',
	    component: () =>
	        import ('@/views/print/sharedvc/sharedvcexfactoryZijinfangols.vue')
	},
    {
        id: 28,
        path: '/sharedvcback',
        name: 'sharedvcback',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcback.vue')
    },
    {
        id: 29,
        path: '/sharedvcinstall',
        name: 'sharedvcinstall',
        component: () =>
            import ('@/views/print/sharedvc/sharedvcinstall.vue')
    },
    {
        id: 31,
        path: '/photoid',
        name: 'photoid',
        component: () =>
            import ('@/views/print/photo/photoid/photoid.vue')
    },
    {
        id: 33,
        path: '/resourcesglobal',
        name: 'resourcesglobal',
        component: () =>
            import ('@/views/print/resources/resourcesglobal/resourcesglobal.vue')
    },
    {
        id: 34,
        path: '/resourcesclassify',
        name: 'resourcesclassify',
        component: () =>
            import ('@/views/print/resources/resourcesclassify/resourcesclassify.vue')
    },
    {
        id: 37,
        path: '/printresources',
        name: 'printresources',
        component: () =>
            import ('@/views/education/files/printresources/printresources.vue')
    },
    {
        id: 38,
        path: '/fileclassify',
        name: 'fileclassify',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassify.vue')
    },
    {
        id: 39,
        path: '/labelmanage',
        name: 'labelmanage',
        component: () =>
            import ('@/views/education/files/labelmanage/labelmanage.vue')
    },
    {
        id: 40,
        path: '/recommendposition',
        name: 'recommendposition',
        component: () =>
            import ('@/views/education/files/recposition/recommendposition.vue')
    },
    {
        id: 42,
        path: '/curriculumlist',
        name: 'curriculumlist',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumlist.vue')
    },
    // 课程订单列表
    {

        path: '/curriculumorder',
        name: 'curriculumorder',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumorder.vue')
    },
    {
        id: 43,
        path: '/curriculumclassify',
        name: 'curriculumclassify',
        component: () =>
            import ('@/views/education/curriculum/curriculumclassify/curriculumclassify.vue')
    },
    {
        id: 44,
        path: '/coursewarelist',
        name: 'coursewarelist',
        component: () =>
            import ('@/views/education/curriculum/coursewarelist/coursewarelist.vue')
    },
    {
        id: 45,
        path: '/coursewareclassify',
        name: 'coursewareclassify',
        component: () =>
            import ('@/views/education/curriculum/coursewareclassify/coursewareclassify.vue')
    },
    {
        id: 117,
        path: '/department',
        name: 'department',
        component: () =>
            import ('@/views/power/power/department/department.vue')
    },
    {
        id: 118,
        path: '/power',
        name: 'power',
        component: () =>
            import ('@/views/power/power/power/power.vue')
    },
    {
        id: 119,
        path: '/user',
        name: 'user',
        component: () =>
            import ('@/views/power/power/user/user.vue')
    },
    {
        id: 546,
        path: '/accountset',
        name: 'accountset',
        component: () =>
            import ('@/views/power/power/accountset/accountset.vue')
    },
    {
        id: 123,
        path: '/loginlog',
        name: 'loginlog',
        component: () =>
            import ('@/views/power/menu/loginlog/loginlog.vue')
    },
    {
        id: 539,
        path: '/exportlist',
        name: 'exportlist',
        component: () =>
            import ('@/views/power/menu/exportlist/exportlist.vue')
    },
    {
        id: 554,
        path: '/flowermanlist',
        name: 'flowermanlist',
        component: () =>
            import ('@/views/flowerman/flowerman/flowermanlist.vue')
    },
    {
        // 设置-店铺-首页导航
        id: 558,
        path: '/home',
        name: 'home',
        component: () =>
            import ('@/views/set/shop/home/home.vue')
    },
    {
        // 设置-店铺-个人中心
        id: 559,
        path: '/personalinfo',
        name: 'personalinfo',
        component: () =>
            import ('@/views/set/shop/personalinfo/personalinfo.vue')
    },
    {
        // 购物-设备库-设备库
        id: 696,
        path: '/deviceBank',
        name: '/deviceBank',
        component: () =>
            import ('@/views/shop/deviceBank/deviceBank.vue')
    },



    // //以下是配置页面&子页面等页面路由 --------------------------------------------
    {
        //登录
        path: '/',
        name: 'login',
        component: () =>
            import ('@/views/login/login.vue')
    },
    {
        //404
        path: '/404',
        name: '404',
        component: () =>
            import ('@/components/404.vue')
    },
    {
        //test03
        path: '/test03',
        name: 'test03',
        component: () =>
            import ('@/views/acase/test03.vue')
    },
    {
        // 租户管理-公司列表-功能控制
        path: '/companypowerset',
        name: 'companypowerset',
        component: () =>
            import ('@/views/collaborate/company/companypowerset.vue')
    },
    {
        //合作-公司管理-添加公司
        path: '/companyadd',
        name: 'companyadd',
        component: () =>
            import ('@/views/collaborate/company/companyadd.vue')
    },
    {
        //合作-公司管理-编辑公司
        path: '/companyedit',
        name: 'companyedit',
        component: () =>
            import ('@/views/collaborate/company/companyedit.vue')
    },
    { //打印-共享打印-机器控制-编辑设备-远程控制
        path: '/sharedvccontrolfrp',
        name: 'sharedvccontrolfrp',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrolfrp.vue')
    },
    { //打印-资源管理-资源分类-添加分类
        path: '/resourcesclassifyadd',
        name: 'resourcesclassifyadd',
        component: () =>
            import ('@/views/print/resources/resourcesclassify/resourcesclassifyadd.vue')
    },
    { //打印-证件照打印-证件照-添加尺寸
        path: '/photoidedit',
        name: 'photoidedit',
        component: () =>
            import ('@/views/print/photo/photoid/photoidedit.vue')
    },
    { //打印-证件照打印-证件照-添加尺寸
        path: '/photoidadd',
        name: 'photoidadd',
        component: () =>
            import ('@/views/print/photo/photoid/photoidadd.vue')
    },
    { //打印-资源管理-全球拍资源-添加图片
        path: '/resourcesglobaladd',
        name: 'resourcesglobaladd',
        component: () =>
            import ('@/views/print/resources/resourcesglobal/resourcesglobaladd.vue')
    },
    { //教育-文档管理-打印资源库-添加文档
        path: '/printresourcesadd',
        name: 'printresourcesadd',
        component: () =>
            import ('@/views/education/files/printresources/printresourcesadd.vue')
    },
    { //教育-文档管理-打印资源库-批量添加文档
        path: '/printresourcesaddmore',
        name: 'printresourcesaddmore',
        component: () =>
            import ('@/views/education/files/printresources/printresourcesaddmore.vue')
    },
    { //教育-文档管理-打印资源库-审核文档
        path: '/resourcesexamine',
        name: 'resourcesexamine',
        component: () =>
            import ('@/views/education/files/printresources/resourcesexamine.vue')
    },
    { //教育-文档管理-打印资源库-编辑文档
        path: '/resourcesedit',
        name: 'resourcesedit',
        component: () =>
            import ('@/views/education/files/printresources/resourcesedit.vue')
    },
    { //教育-文档管理-文档分类-添加分类
        path: '/fileclassifyadd',
        name: 'fileclassifyadd',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassifyadd.vue')
    },
    { //教育-文档管理-标签管理-编辑标签
        path: '/labelmanageadd',
        name: 'labelmanageadd',
        component: () =>
            import ('@/views/education/files/labelmanage/labelmanageadd.vue')
    },
    { //教育-文档管理-推荐位配置-添加导航
        path: '/recpositionadd',
        name: 'recpositionadd',
        component: () =>
            import ('@/views/education/files/recposition/recpositionadd.vue')
    },
    { //教育-文档管理-推荐位配置-编辑导航
        path: '/recpositionedit',
        name: 'recpositionedit',
        component: () =>
            import ('@/views/education/files/recposition/recpositionedit.vue')
    },
    { //教育-文档管理-推荐位配置-添加专题
        path: '/recpositionaddspecial',
        name: 'recpositionaddspecial',
        component: () =>
            import ('@/views/education/files/recposition/recpositionaddspecial.vue')
    },
    { //教育-课程管理-课程列表-添加课程
        path: '/curriculumlistadd',
        name: 'curriculumlistadd',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumlistadd.vue')
    },
    { //教育-课程管理-课程分类-添加分类
        path: '/curriculumclassifyadd',
        name: 'curriculumclassifyadd',
        component: () =>
            import ('@/views/education/curriculum/curriculumclassify/curriculumclassifyadd.vue')
    },
    {
        //教育-课程管理-课件列表-添加课件
        path: '/coursewarelistadd',
        name: 'coursewarelistadd',
        component: () =>
            import ('@/views/education/curriculum/coursewarelist/coursewarelistadd.vue')
    },
    { //教育-课程管理-课件分类-添加分类
        path: '/coursewareclassifyadd',
        name: 'coursewareclassifyadd',
        component: () =>
            import ('@/views/education/curriculum/coursewareclassify/coursewareclassifyadd.vue')
    },
    {
        path: '/companyaliset',
        name: 'companyaliset',
        component: () =>
            import ('@/views/collaborate/company/company_aliset.vue')
    },
    {
        path: '/companywxset',
        name: 'companywxset',
        component: () =>
            import ('@/views/collaborate/company/company_wxset.vue')
    },

    { //权限-权限管理-用户管理-添加员工
        path: '/useradd',
        name: 'useradd',
        component: () =>
            import ('@/views/power/power/user/useradd.vue')
    },
    { //权限-权限管理-用户管理-编辑员工
        path: '/useredit',
        name: 'useredit',
        component: () =>
            import ('@/views/power/power/user/useredit.vue')
    },
    { //教育-文档管理-文档分类-编辑分类
        path: '/fileclassifyedit',
        name: 'fileclassifyedit',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassifyedit.vue')
    },
    { //教育-文档管理-文档分类-新增下级
        path: '/fileclassifyaddlast',
        name: 'fileclassifyaddlast',
        component: () =>
            import ('@/views/education/files/fileclassify/fileclassifyaddlast.vue')
    },
    { //教育-文档管理-标签管理-编辑标签
        path: '/labelmanageedit',
        name: 'labelmanageedit',
        component: () =>
            import ('@/views/education/files/labelmanage/labelmanageedit')
    },
    { //教育-课程管理-课程分类-编辑分类
        path: '/curriculumclassifyedit',
        name: 'curriculumclassifyedit',
        component: () =>
            import ('@/views/education/curriculum/curriculumclassify/curriculumclassifyedit.vue')
    },
    { //教育-课程管理-课件分类-编辑分类
        path: '/coursewareclassifyedit',
        name: 'coursewareclassifyedit',
        component: () =>
            import ('@/views/education/curriculum/coursewareclassify/coursewareclassifyedit.vue')
    },
    {
        //教育-课程管理-课件列表-编辑课件
        path: '/coursewarelistedit',
        name: 'coursewarelistedit',
        component: () =>
            import ('@/views/education/curriculum/coursewarelist/coursewarelistedit.vue')
    },
    {
        //教育-课程管理-课程列表-编辑课程
        path: '/curriculumlistedit',
        name: 'curriculumlistedit',
        component: () =>
            import ('@/views/education/curriculum/curriculumlist/curriculumlistedit.vue')
    },

    {
        //打印-共享打印-机器控制-编辑设备
        path: '/sharedvccontroledit',
        name: 'sharedvccontroledit',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontroledit.vue')
    },
    { //打印-共享打印-机器控制-查看日志
        path: '/sharedvccontrolseelog',
        name: 'sharedvccontrolseelog',
        component: () =>
            import ('@/views/print/sharedvc/sharedvccontrolseelog.vue')
    },
    { //打印-共享打印-机器控制-在线时长
        path: '/dvcisonline',
        name: 'dvcisonline',
        component: () =>
            import ('@/views/print/sharedvc/dvcisonline.vue')
    },
    { //打印-资源管理-全球拍资源-编辑图片
        path: '/resourcesglobaledit',
        name: 'resourcesglobaledit',
        component: () =>
            import ('@/views/print/resources/resourcesglobal/resourcesglobaledit.vue')
    },
    { //打印-资源管理-分类列表-编辑分类
        path: '/resourcesclassifyedit',
        name: 'resourcesclassifyedit',
        component: () =>
            import ('@/views/print/resources/resourcesclassify/resourcesclassifyedit.vue')
    },
    {
        //设置权限设置
        path: '/vpowerset',
        name: 'vpowerset',
        component: () =>
            import ('@/views/power/power/power/powerset.vue')
    },
    {
        //评分列表
        path: '/printreSourcesMarklist',
        name: 'printreSourcesMarklist',
        component: () =>
            import ('@/views/education/files/printresources/printreSourcesMarklist.vue')
    },
    {
        // 设置-店铺-个人中心-添加菜单
        path: '/menuadd',
        name: 'menuadd',
        component: () =>
            import ('@/views/set/shop/personalinfo/menuadd.vue')
    },
    {
        // 设置-店铺-个人中心-编辑菜单
        path: '/menuedit',
        name: 'menuedit',
        component: () =>
            import ('@/views/set/shop/personalinfo/menuedit.vue')
    },
    {
        // 设置-店铺-个人中心-编辑工具栏
        path: '/toolsedit',
        name: 'toolsedit',
        component: () =>
            import ('@/views/set/shop/personalinfo/toolsedit.vue')
    },
    {
        // 设置-店铺-首页导航-添加导航
        path: '/homeadd',
        name: 'homeadd',
        component: () =>
            import ('@/views/set/shop/home/homeadd.vue')
    },
    {
        // 设置-店铺-首页导航-编辑导航
        path: '/homeedit',
        name: 'homeedit',
        component: () =>
            import ('@/views/set/shop/home/homeedit.vue')
    },
    {
        // 租户管理-租户管理-公司列表-资金明细
        path: '/companymoneydetails',
        name: 'companymoneydetails',
        component: () =>
            import ('@/views/collaborate/company/companymoneydetails.vue')
    },
    {
        // 设置-审核管理-审核小程序图文配置
        path: '/setphotoexamine',
        name: 'setphotoexamine',
        component: () =>
            import ('@/views/set/examine/setphotoexamine/setphotoexamine.vue')
    },
    {
        // 运营-京币-活动送京币
        id: 578,
        path: '/yingbipresent',
        name: 'yingbipresent',
        component: () =>
            import ('@/views/operate/yingbi/yingbipresent.vue')
    },
    {
        // 运营-京币-活动送京币-添加
        path: '/yingbipresentadd',
        name: 'yingbipresentadd',
        component: () =>
            import ('@/views/operate/yingbi/yingbipresentadd.vue')
    },
    {
        // 运营-京币-活动送京币-详情
        path: '/yingbipresentinfo',
        name: 'yingbipresentinfo',
        component: () =>
            import ('@/views/operate/yingbi/yingbipresentinfo.vue')
    },

    {
        id: 586,
        // 打印-标签管理-标签组管理
        path: '/tagsgroup',
        name: 'tagsgroup',
        component: () =>
            import ('@/views/print/tags/tagsgroup/tagsgroup.vue')
    },
    {
        id: 587,
        // 打印-标签管理-标签管理
        path: '/tagslist',
        name: 'tagslist',
        component: () =>
            import ('@/views/print/tags/tagslist/tagslist.vue')
    },
    {
        id: 598,
        // 打印-设备开关
        path: '/sharedvceopenclose',
        name: 'sharedvceopenclose',
        component: () =>
            import ('@/views/print/sharedvc/sharedvceopenclose/sharedvceopenclose.vue')
    },

    // ***************仓库管理相关********************************************
    {
        id: 608,
        // 仓库-出入口管理-入库单
        path: '/goinmonad',
        name: 'goinmonad',
        component: () =>
            import ('@/views/wmsystem/goinout/goinmonad/goinmonad.vue')
    },
    {
        // id: 598,
        // 仓库-出入口管理-入库单-详情
        path: '/goinmonadinfo',
        name: 'goinmonadinfo',
        component: () =>
            import ('@/views/wmsystem/goinout/goinmonad/goinmonadinfo.vue')
    },
    {
        // id: 598,
        // 仓库-出入口管理-入库单-添加
        path: '/goinmonadadd',
        name: 'goinmonadadd',
        component: () =>
            import ('@/views/wmsystem/goinout/goinmonad/goinmonadadd.vue')
    },
    {
        id: 615,
        // 仓库-出入口管理-出库单
        path: '/gooutmonad',
        name: 'gooutmonad',
        component: () =>
            import ('@/views/wmsystem/goinout/gooutmonad/gooutmonad.vue')
    },
    {
        // id: 598,
        // 仓库-出入口管理-出库单-详情
        path: '/gooutmonadinfo',
        name: 'gooutmonadinfo',
        component: () =>
            import ('@/views/wmsystem/goinout/gooutmonad/gooutmonadinfo.vue')
    },
    {
        id: 614,
        // 仓库-出入口管理-出库报单列表
        path: '/gooutreportmonad',
        name: 'gooutreportmonad',
        component: () =>
            import ('@/views/wmsystem/goinout/reportmonad/gooutreportmonad.vue')
    },
    {
        // id: 598,
        // 仓库-出入口管理-出库报单列表
        path: '/gooutreportmonadinfo',
        name: 'gooutreportmonadinfo',
        component: () =>
            import ('@/views/wmsystem/goinout/reportmonad/gooutreportmonadinfo.vue')
    },
    {
        id: 654,
        // 仓库-出入口管理-我的出库接单
        path: '/gooutreceivemonad',
        name: 'gooutreceivemonad',
        component: () =>
            import ('@/views/wmsystem/goinout/receivemonad/gooutreceivemonad.vue')
    },
    {
        // id: 598,
        // 仓库-出入口管理-我的出库接单-详情
        path: '/gooutreceivemonadinfo',
        name: 'gooutreceivemonadinfo',
        component: () =>
            import ('@/views/wmsystem/goinout/receivemonad/gooutreceivemonadinfo.vue')
    },
    {
        id: 612,
        // 仓库-出入口管理-实际存储量
        path: '/reservenums',
        name: 'reservenums',
        component: () =>
            import ('@/views/wmsystem/goinout/reservenums/reservenums.vue')
    },
    {
        id: 658,
        // 仓库-出入口管理-设备列表
        path: '/dvclist',
        name: 'dvclist',
        component: () =>
            import ('@/views/wmsystem/goinout/dvclist/dvclist.vue')
    },
    {
        id: 660,
        // 仓库-出入口管理-设备出入库记录
        path: '/dvcgoinoutlog',
        name: 'dvcgoinoutlog',
        component: () =>
            import ('@/views/wmsystem/goinout/dvcgoinoutlog/dvcgoinoutlog.vue')
    },
    // 仓库-基本配置
    {
        id: 603,
        // 仓库-基础配置-仓库管理
        path: '/godown',
        name: 'godown',
        component: () =>
            import ('@/views/wmsystem/baseset/godown/godown.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-仓库管理-添加
        path: '/godownadd',
        name: 'godownadd',
        component: () =>
            import ('@/views/wmsystem/baseset/godown/godownadd.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-仓库管理-编辑
        path: '/godownedit',
        name: 'godownedit',
        component: () =>
            import ('@/views/wmsystem/baseset/godown/godownedit.vue')
    },
    {
        id: 627,
        // 仓库-基础配置-入库类型
        path: '/gointype',
        name: 'gointype',
        component: () =>
            import ('@/views/wmsystem/baseset/gointype/gointype.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-入库类型-添加
        path: '/gointypeadd',
        name: 'gointypeadd',
        component: () =>
            import ('@/views/wmsystem/baseset/gointype/gointypeadd.vue')
    },
    {
        id: 632,
        // 仓库-基础配置-出库类型
        path: '/goouttype',
        name: 'goouttype',
        component: () =>
            import ('@/views/wmsystem/baseset/goouttype/goouttype.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-出库类型-添加
        path: '/goouttypeadd',
        name: 'goouttypeadd',
        component: () =>
            import ('@/views/wmsystem/baseset/goouttype/goouttypeadd.vue')
    },
    {
        id: 642,
        // 仓库-基础配置-产品类别
        path: '/productclass',
        name: 'productclass',
        component: () =>
            import ('@/views/wmsystem/baseset/productclass/productclass.vue')
    },
    {
        id: 646,
        // 仓库-基础配置-产品管理
        path: '/productmanage',
        name: 'productmanage',
        component: () =>
            import ('@/views/wmsystem/baseset/productmanage/productmanage.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-产品管理-添加
        path: '/productmanageadd',
        name: 'productmanageadd',
        component: () =>
            import ('@/views/wmsystem/baseset/productmanage/productmanageadd.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-产品管理-编辑
        path: '/productmanageedit',
        name: 'productmanageedit',
        component: () =>
            import ('@/views/wmsystem/baseset/productmanage/productmanageedit.vue')
    },
    {
        id: 619,
        // 仓库-基础配置-供应商管理
        path: '/supplier',
        name: 'supplier',
        component: () =>
            import ('@/views/wmsystem/baseset/supplier/supplier.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-供应商管理-添加
        path: '/supplieradd',
        name: 'supplieradd',
        component: () =>
            import ('@/views/wmsystem/baseset/supplier/supplieradd.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-供应商管理-编辑
        path: '/supplieredit',
        name: 'supplieredit',
        component: () =>
            import ('@/views/wmsystem/baseset/supplier/supplieredit.vue')
    },
    {
        id: 637,
        // 仓库-基础配置-计量单位
        path: '/unitnums',
        name: 'unitnums',
        component: () =>
            import ('@/views/wmsystem/baseset/unitnums/unitnums.vue')
    },
    {
        // id: 598,
        // 仓库-基础配置-计量单位-添加
        path: '/unitnumsadd',
        name: 'unitnumsadd',
        component: () =>
            import ('@/views/wmsystem/baseset/unitnums/unitnumsadd.vue')
    },
    {
        id: 663,
        // 仓库-基础配置-手机登录授权用户
        path: '/phoneloginempower',
        name: 'phoneloginempower',
        component: () =>
            import ('@/views/wmsystem/baseset/phoneloginempower/phoneloginempower.vue')
    },
    {
        id: 722,
        // 仓库-基础配置-入库清单
        path: '/warehousemanifest',
        name: 'warehousemanifest',
        component: () =>
            import ('@/views/wmsystem/baseset/warehousemanifest/warehousemanifest.vue')
    },
    {
        id: 717,
        // 仓库-基础配置-出库清单
        path: '/exportmanifest',
        name: 'exportmanifest',
        component: () =>
            import ('@/views/wmsystem/baseset/exportmanifest/exportmanifest.vue')
    },
    {
        id: 718,
        // 仓库-基础配置-现库存
        path: '/presentstock',
        name: 'presentstock',
        component: () =>
            import ('@/views/wmsystem/baseset/presentstock/presentstock.vue')
    },
    // ********************** 风控-相关页面路由 ********************************
    {
        id: 670,
        // 风控-风控管理-批次管理
        path: '/batchmanage',
        name: 'batchmanage',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanage/batchmanage.vue')
    },
    {
        // id: 663,
        // 风控-风控管理-批次管理-新增批次
        path: '/batchmanageadd',
        name: 'batchmanageadd',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanage/batchmanageadd.vue')
    },
    {
        // id: 663,
        // 风控-风控管理-批次管理-验收
        path: '/batchmanagechack',
        name: 'batchmanagechack',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanage/batchmanagechack.vue')
    },
    {
        // id: 663,
        // 风控-风控管理-批次管理-编辑
        path: '/batchmanageedit',
        name: 'batchmanageedit',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanage/batchmanageedit.vue')
    },
    {
        // id: 663,
        // 风控-风控管理-批次管理-详情
        path: '/batchmanageinfo',
        name: 'batchmanageinfo',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanage/batchmanageinfo.vue')
    },
    {
        id: 682,
        // 风控-风控管理-批次管理(资方)
        path: '/batchmanagefunder',
        name: 'batchmanagefunder',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanagefunder/batchmanagefunder.vue')
    },
    {
        // id: 663,
        // 风控-风控管理-批次管理(资方)-详情
        path: '/batchmanagefunderinfo',
        name: 'batchmanagefunderinfo',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchmanagefunder/batchmanagefunderinfo.vue')
    },
    {
        id: 678,
        // 风控-风控管理-批次款统计
        path: '/batchpayment',
        name: 'batchpayment',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchpayment/batchpayment.vue')
    },
    {
        id: 680,
        // 风控-风控管理-批次款统计(资方)
        path: '/batchpaymentfunder',
        name: 'batchpaymentfunder',
        component: () =>
            import ('@/views/riskcontrol/riskmanage/batchpaymentfunder/batchpaymentfunder.vue')
    },
    {
        // 风控-第三方租期数据-订单列表
        id: 703,
        path: '/thirdOrderList',
        name: 'thirdOrderList',
        component: () =>
            import ('@/views/riskcontrol/thirdPartyTenancy/thirdOrderList/thirdOrderList.vue')
    },
    {
        // 风控-第三方租期数据-账单列表
        id: 707,
        path: '/thirdBillList',
        name: 'thirdBillList',
        component: () =>
            import ('@/views/riskcontrol/thirdPartyTenancy/thirdBillList/thirdBillList.vue')
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;